<template>
  <div>
    <Breadcrumb />
    <b-row class="download-row">
      <div class="download-backgroud">
        <div id="card-download-shir">
          <div class="content-top">
            <p class="page-title">{{ $t('DownloadPage.TitlePart1') }}</p>
            <p class="page-title">{{ $t('DownloadPage.TitlePart2') }}</p>
            <p class="page-subtitle">{{ $t('DownloadPage.Subtitle') }}</p>
            <b-button class="download-button" @click="getLinkDownloadClient">
              <imgDownloadIcon v-if="windowWidth > 480"/>
              <imgDownloadSmallIcon v-else />
              {{ $t('DownloadPage.TextButton') }}
            </b-button>
          </div>
          <div class="break-line" />
          <div class="content-bottom">
            <p class="page-title">Authentication Key</p>
            <p class="page-subtitle">{{ $t('DownloadPage.AuthenticationSubtitle')}}</p>
            <div class="key-row">
              
              <b-input-group  :class="['auth-key-input', flagGeneratingKey && 'disabled']">
                <b-form-input
                    v-model="securityKey"
                    :type="authKeyFieldType"
                    :readonly="!flagGeneratingKey"
                    class="input-key"
                    
                />
                <div class="input-icon-eye" >
                  <feather-icon
                    :icon="authKeyToggleIcon"
                    class="cursor-pointer"
                    @click="toggleAuthKey"
                  />
                </div>
              </b-input-group>
              <div id="shir-tooltip-refresh-key">
                <b-button 
                  class="btn-key-row btn-refresh" 
                  @click="!flagGeneratingKey && updateSecurityKey()" 
                >
                  <span>
                    <RefreshIcon :class="[{'rotate-refresh-icon': flagRotateRefreshIcon}]"/>
                  </span>
                </b-button>
              </div>
              <b-tooltip
                custom-class="tooltip-shir"
                target="shir-tooltip-refresh-key"
                triggers="hover"
                positioning="top"
                placement="top"
                v-if="windowWidth > 768"
              >
                <div 
                  class="info-tooltip-shir" 
                >
                  {{ $t('DownloadPage.RefreshKey') }}
                </div>
              </b-tooltip>
              
              <div id="shir-tooltip-copy-key">
                <b-button 
                  @click="copyText"  
                  class="btn-key-row btn-copy" 
                  :disabled="flagGeneratingKey"
                >
                  <span>
                    <CopyIcon v-if="!keyCopied"/>
                    <CopiedIcon v-else/>
                  </span>
                </b-button>
              </div>
              <b-tooltip
                custom-class="tooltip-shir"
                target="shir-tooltip-copy-key"
                triggers="hover"
                positioning="top"
                placement="top"
                v-if="windowWidth > 768"
              >
                <div 
                  class="info-tooltip-shir" 
                >
                  {{ !keyCopied ? $t('DownloadPage.CopyKey') : $t('DownloadPage.Copied') }}
                </div>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <span ref="securityKey"></span>
    </b-row>
  </div>
</template>

<script>
import Breadcrumb from '../layouts/components/Breadcrumb.vue'
import imgDownloadIcon from '@/assets/images/icons/download-icon.svg'
import imgDownloadSmallIcon from '@/assets/images/icons/download-icon-small.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RefreshIcon from '@/assets/images/icons/refresh-medium-orange-icon.svg';
import CopyIcon from '@/assets/images/icons/copy-medium-white-icon.svg';
import CopiedIcon from '@/assets/images/icons/check-circle-white-icon.svg';
import { mapMutations } from "vuex";
import {
  BRow, BButton, BInputGroup, BInputGroupAppend, BFormInput, BTooltip,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BButton,
    Breadcrumb,
    imgDownloadIcon,
    imgDownloadSmallIcon,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    RefreshIcon,
    CopyIcon,
    BTooltip,
    CopiedIcon
  },
  computed: {
    authKeyToggleIcon() {
      return this.authKeyFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  data() {
    return {
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      windowWidth: window.innerWidth,
      securityKey: '',
      authKeyFieldType: 'password',
      flagRotateRefreshIcon: false,
      keyCopied: false,
      flagGeneratingKey: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    this.getSecurityKey(true);
    this.closeTooltip();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getLinkDownloadClient() {
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/link-client-download`)
        .then(response => {
          window.open(response.data.link, '_blank')
          this.showToast(this.$t('DownloadPage.TitleToast'), 'SmileIcon', this.$t('DownloadPage.MessageToast'), 'success')
        })
        .catch(error => this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger'))
    },
    toggleAuthKey() {
      this.authKeyFieldType = this.authKeyFieldType === 'password' ? 'text' : 'password'
    },
    copyText() {
      if(this.keyCopied) return;
      
      const storage = document.createElement('textarea');
      storage.value = this.securityKey;

      if(storage.value != ''){
        this.$refs['securityKey'].appendChild(storage);
        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.$refs['securityKey'].removeChild(storage);
        this.keyCopied = true;
        setTimeout(() => this.keyCopied = false, 2000);
      }
    },
    getSecurityKey(firstLoading=false) {
      if(firstLoading) this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`api/${this.prefix}/security-key?site_guid=${this.currentSite.guid}`)
        .then(res => {
          this.securityKey = res.data.security_key
          this.flagRotateRefreshIcon = false;
          this.flagGeneratingKey = false;
          this.authKeyFieldType = 'password';
          if(firstLoading) this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })
    },
    updateSecurityKey() {
      this.flagRotateRefreshIcon = true;
      this.flagGeneratingKey = true;

      let body = {
        "site_guid": this.currentSite.guid
      };

      this.$http.post(`api/${this.prefix}/security-key?site_guid=${this.currentSite.guid}`, body)
        .then(() => this.getSecurityKey());
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    closeTooltip(){
      window.addEventListener('scroll', ()=>{
        let tooltips = document.querySelectorAll('.tooltip');
        if(tooltips.length > 0){
          for (let tooltip of tooltips) {
            tooltip.style.display = 'none';
          }
        }
      });
    },
  }
}
</script>

<style lang="scss">
.tooltip-shir {
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0 0 12px 0;
  .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-shir {
          max-width: 100%;
          padding: 7px 8px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          letter-spacing: 0.16px;
          border: 1px solid transparent;
      }
  }

    .arrow {
        &::before {
            border-top-color: #323232;
            border-bottom-color: #323232;
        }
    }
}

.download-backgroud {
  background-image: url('../assets/images/icons/cubos.png');
  background-color: #362f2b;
  background-size: 175px;
  width: 100vw;
  height: 80vh;
  min-height: 484px !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-row {
  margin-left: -17px !important;
  margin-right: -17px !important;
}


#card-download-shir {
  box-sizing: border-box;
  background: #fff;
  padding: 40px 0;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 565px;
  max-width: 565px;
  border-radius: 6px;

  .break-line {
    display: block;
    height: 1px;
    background: #CFC4BE;
  }

  .content-top,
  .content-bottom {
    padding:  0 40px 0;
    width: 100%;
    .page-title {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #4C4541;
      margin: 0;
    }

    .page-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #998F8A;
      padding: 8px 0 16px 0;
      margin: 0;
    }
  }

  .content-top {
    padding-bottom: 24px;
    
    .download-button {
      width: 100%;
      padding: 10.5px 0;
      background-color: #974900 !important;
      border-radius: 5px !important;
      border-color: #974900 !important;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: none !important;
        background: #9F5714 !important;
      }

      &:active {
        background: #A45F1F !important;
      }

      svg {
        margin-right: 9.75px !important;
      }
    }
    
  }

  .content-bottom {
    padding-top: 24px;

    .key-row {
      display: flex;
      .auth-key-input {
        border: 1px solid #CFC4BE;
        border-radius: 5px;
        &.disabled{
          border: 1px solid #7E7570 !important;
          background: #EEEEEE !important;
          .input-key{
            background: #EEEEEE !important;
          }
          
        }
        .input-icon-eye{
          padding: 8px 11px 8px 0;
          display: flex;
          align-items: center;
        }
        .input-key{
          border: none !important;
          
        }
        &.input-group:not(.bootstrap-touchspin):focus-within {
          box-shadow: none !important;
        }
  
        input {
          border-right: none !important;
          border-start-start-radius: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4C4541;
          padding: 5.132px 14px !important;
          border: 1px solid #CFC4BE;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;

          &:focus {
            border: 1px solid #CFC4BE;
            padding-left: 14px;
            box-shadow: none !important;
          }
  
          &[readonly] {
            background-color: #fff;
          }
        }
  
        .input-group-text {
          border: 1px solid #CFC4BE !important;
        }
      }

      .btn-key-row {
        margin-left: 8px;
        box-sizing: border-box;
        padding: 10.32px 11px !important;
        border: 1px solid;
        height: 100%;

        &:hover {
          box-shadow: none !important;
        }

        &.btn-refresh{
          border-color: #974900 !important;
          background: #fff !important;

          &:hover {
              background: #FFEDE2 !important;
          }

          &:active {
              background: #FFDBC4 !important;
          }

          svg {
            &.rotate-refresh-icon {
              animation: rotaterefresh 1s linear infinite;
            }

            @keyframes rotaterefresh {
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }

        &.btn-copy {
          border-color: #974900 !important;
          background: #974900 !important;

          &:hover {
            background: #9F5714 !important;
          }

          &:active {
            background: #A45F1F !important;
          }
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .download-backgroud {
    min-height: 80vh !important;
    height: 100%;
    padding: 10px;
  }
  #card-download-shir { 
    max-width: 288px !important;
    width: 100%;
    padding: 24px 0 !important;

    .content-top,
    .content-bottom {
      padding-inline: 16px !important;
    }

    .content-top {
      .download-button {
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.0125em !important;
      }
    }

    .page-title {
      font-size: 16px !important;
      line-height: 26px !important;
    }

    .page-subtitle {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
}

@media (max-width: 768px) and (min-width: 480px){
  #card-download-shir {
    width: 465px !important;
  }
}
</style>
  